import { extractLocale } from "../lang/index.js";
import { modifyUrl } from "vike/modifyUrl";

export const onBeforeRoute = (pageContext) => {
  const url = pageContext.urlParsed;
  const { urlPathnameWithoutLocale, locale } = extractLocale(url.pathname);

  const urlLogical = modifyUrl(url.href, { pathname: urlPathnameWithoutLocale });

  return {
    pageContext: {
      locale,
      urlLogical,
    },
  };
};
