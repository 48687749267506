import games from "~~/games";

export default (pageContext) => {
  const { urlPathname } = pageContext;
  const slug = urlPathname.slice(1);
  if (!games[slug]) return false;

  return {
    routeParams: { slug },
  };
};
