import games from "~~/games";

export default (pageContext) => {
  const { urlPathname } = pageContext;
  const splitted = urlPathname.split("/");
  const slug = splitted[1];
  if (!games[slug] || splitted[2] !== "play") return false;

  return {
    routeParams: { slug },
  };
};
